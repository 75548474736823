export const locales = require('./locales.json').data;

let languageSelectorDropdownItems = document.querySelectorAll('.language-dropdown .dropdown-item');
let activeLanguageTexts = document.querySelectorAll('.current-language');

let currentLocaleId = 'default';
for (let locale of locales) {
    if (window.location.pathname.includes(`/${locale}/`)) {
        currentLocaleId = locale;
    }
}

languageSelectorDropdownItems.forEach(item => {
    item.classList.remove('active');
    if (item.id === currentLocaleId) {
        item.classList.add('active');
        activeLanguageTexts.forEach((text) => {
            text.textContent = item.dataset.i18nCode;
        })
    }
});