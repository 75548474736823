import {locales} from './languageHelper';
import * as lunr from 'lunr';

let documents, searchIndex;
getSearchDocuments();
getSearchIndex();

let openSearchModalContainer = document.getElementById('openSearchModalButtonContainer');
let modalSearchInput = document.getElementById('modalSearchInput');
let resetSearchButton = document.getElementById('resetSearchButton');
let resetSearchButtonPlaceholder = document.getElementById('resetSearchButtonPlaceholder');

if (openSearchModalContainer) {
    openSearchModalContainer.addEventListener('click', () => {
        let searchFormInput = document.getElementById('modalSearchInput');
        setTimeout(() => searchFormInput.focus(), 500);
    });
}

if (modalSearchInput) {
    modalSearchInput.addEventListener('input', () => {
        const searchTerm = document.getElementById('modalSearchInput').value;
        const resetButton = document.getElementById('resetSearchButton');
        const resetButtonPlaceholder = document.getElementById('resetSearchButtonPlaceholder');

        if (searchTerm.length > 0 && resetButton.classList.contains('d-none')) {
            resetButton.classList.remove('d-none');
            resetButtonPlaceholder.classList.add('d-none');
        } else if (searchTerm.length === 0 && !resetButton.classList.contains('d-none')) {
            resetButton.classList.add('d-none');
            resetButtonPlaceholder.classList.remove('d-none');
        }
    });

    modalSearchInput.addEventListener('input', debounce(async e => {
        e.preventDefault();
        e.stopPropagation();

        const resultListContainer = document.getElementById('searchResultContainer');
        const resultList = document.getElementById('searchResultList');
        const resultPlaceholder = document.getElementById('searchStartScreen');

        const searchHits = search();
        resultList.innerHTML = '';
        if (searchHits.length > 0) {

            for (let hit of searchHits) {
                const doc = documents[hit.ref];

                let anchor = document.createElement('a');
                anchor.setAttribute('href', doc.url);

                let icon = document.createElement('i');
                icon.classList.add('bi', 'bi-file-earmark', 'pe-2');

                anchor.append(icon);
                anchor.innerHTML = anchor.innerHTML + doc.title;

                let elem = document.createElement('li');
                elem.appendChild(anchor);

                resultList.appendChild(elem);
            }

            resultListContainer.classList.remove('d-none');
            resultPlaceholder.classList.add('d-none');
        } else {
            resultListContainer.classList.add('d-none');
            resultPlaceholder.classList.remove('d-none');
        }
    }, 300));
}

if (resetSearchButton) {
    resetSearchButton.addEventListener('click', e => {
        e.preventDefault();
        e.stopPropagation();

        const searchInput = document.getElementById('modalSearchInput');
        searchInput.value = '';
        searchInput.focus();
        document.getElementById('resetSearchButton').classList.add('d-none');
        document.getElementById('resetSearchButtonPlaceholder').classList.remove('d-none');
    });
}

if (resetSearchButtonPlaceholder) {
    resetSearchButtonPlaceholder
        .addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
        });
}

function search() {
    const searchTerm = document.getElementById('modalSearchInput').value;
    if (searchTerm === '') {
        return [];
    }

    const searchResults = searchIndex.search(`${searchTerm}~3`);

    return searchResults.slice(0, 4);
}

async function getSearchDocuments() {
    if (document.querySelector('#openSearchModalButtonContainer')) {
        const searchDocumentsUrl = buildFileUrl('/searchDocuments.json');

        const res = await fetch(searchDocumentsUrl);
        if (res.ok) {
            documents = await res.json();
        }
    }
}

async function getSearchIndex() {
    if (document.querySelector('#openSearchModalButtonContainer')) {
        const searchIndexUrl = buildFileUrl('/searchIndex.json');

        const res = await fetch(searchIndexUrl);
        if (res.ok) {
            const indexData = await res.json();
            searchIndex = lunr.Index.load(indexData);
        }
    }
}

function buildFileUrl(fileName) {
    const fileUrlPrefix = '';
    let url = new URL(window.location);

    // build the url for the correct localized version of the file
    let fileUrl = fileName;

    for (let locale of locales) {
        if (url.pathname.includes('/' + locale)) {
            return `${fileUrlPrefix}/${locale}${fileUrl}`;
        }
    }

    return fileUrlPrefix + fileUrl;
}

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        let context = this, args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) {
            func.apply(context, args);
        }
    };
}